body { background-color: $COL2; }
body { color: $COL5; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Novecentowide';
font-weight: 300;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 26px;

@media #{$large-up} {
font-size: 32px;

}
}
h2 {
font-family: 'Novecentowide';
font-weight: 300;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 28px;

}
@media #{$large-up} {
font-size: 30px;

}
}
h3 {
font-family: 'Novecentowide';
font-weight: 300;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 28px;

}
@media #{$large-up} {
font-size: 30px;

}
}
h4 {
font-family: 'Novecentowide';
font-weight: 300;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 24px;

}
}
h5 {
font-family: 'Novecentowide';
font-weight: 300;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
font-family: 'Novecentowide';
font-weight: 300;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 16px;

}
.button {
font-family: 'Novecentowide';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Open Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Novecentowide';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

}
nav.mainmenu > .menu-item > a {
font-family: 'Novecentowide';
font-weight: 300;
font-style: normal;
line-height: 1;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Novecentowide';
font-weight: 300;
font-style: normal;
line-height: 1;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: 300;
font-style: normal;
line-height: 1.8;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: 300;
font-style: normal;
line-height: 1.8;
letter-spacing: 0;
font-size: 20px;

@media #{$large-up} {
font-size: 24px;

}
}
p { padding-bottom: 1em; }
a {color: $COL6}
a:hover {color: $COL7}
/* Spacer:33 */
.MES33 {
padding: 0;

@media #{$large-up} {
padding: 20px 0;

}
 }
/* form spacer:25 */
.MES25 {
padding: 5px;

 }
/* Form Button:26 */
.MES26 {
background-color: $COL9;
&:hover { background-color: $COL3;}
color: $COL2;
font-size: 14px;
@media #{$medium-up} {
font-size: 19.2px;
};
@media #{$large-up} {
font-size: 20.8px;
};
border-radius: 10px;
padding: 5px;

border-width: 2px;
border-style: solid;
border-color: transparent;
 }
/* Primary:27 */
.MES27 {
background-color: $COL18;
 }
/* Primary:28 */
.MES28 {
background-color: $COL18;
 }
.MES28 {
background-color: $COL18;
 }
/* Drag & Drop image upload:29 */
.MES29 {
background-color: $COL16;
color: $COL2;
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
min-height:50px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 100px;};
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

border-width: 2px;
border-style: solid;
border-color: $COL8;
 }
.MES29 {
background-color: $COL16;
color: $COL2;
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
min-height:50px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 100px;};
border-radius: 10px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px;

border-width: 2px;
border-style: solid;
border-color: $COL8;
 }
cite.MEC29{
color: $COL2;
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
}
/* Download Button:30 */
.MES30 {
background-color: $COL1;
&:hover { background-color: $COL1;}
color: $COL17;
&:hover { color: $COL2;}
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 32px;
};
border-radius: 3px;
padding: 5px;

border-width: 1px;
border-style: solid;
border-color: $COL17;
&:hover { border-color: $COL2; }
 }
/* Download Shadow Panel:31 */
.MES31 {
&:hover, &.hover { background-color: $COL8;}
 }
.MES31 {
&:hover, &.hover { background-color: $COL8;}
 }
/* Responsive Menu:32 */
nav.responsive-menu {
.menu-item.MEC32{background-color: $COL8;
}
& .menu-item.MEC32, & .menu-item.MEC32 > div.menu-item-wrap{ & > a.MEC32, & > i{color: $COL2;
text-transform: uppercase;
text-align: left;
}
  }
& .menu-item.MEC32 { border:0;
border-color: $COL2;
border-style: dotted;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 5px 15px;}

& .sub-menu{.menu-item.MEC32{background-color: $COL8;
}
 .sub-menu {.menu-item.MEC32{background-color: $COL8;
}
}}

 }
/* Space Holder:34 */
.MES34 {
padding: 29px 0;

@media #{$medium-up} {
padding: 0;

}
 }
/* Image Fix Panel white 2:23 */
.MES23 {
background-color: $COL2;
& > .underlay, & > .inner-overlay { opacity: 0.1 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://duttongarage.com/img/72593/49007');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
background-attachment: fixed;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}padding: 15px;

@media #{$large-up} {
padding: 100px 50px;

}
 }
.MES23 {
background-color: $COL2;
& > .underlay, & > .inner-overlay { opacity: 0.1 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://duttongarage.com/img/72593/49007');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
background-attachment: fixed;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}padding: 15px;

@media #{$large-up} {
padding: 100px 50px;

}
 }
/* Product Desc Panel:35 */
.MES35 {
background-color: $COL2;
color: $COL3;
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
 }
.MES35 {
background-color: $COL2;
color: $COL3;
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
h1.MEC35, h2.MEC35, h3.MEC35, h4.MEC35, h5.MEC35, h6.MEC35 { color: $COL3;
 }
 }
a.MEC35 { color: $COL3;
&:hover { color: $COL3; }
 }
cite.MEC35{
color: $COL3;
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
}
/* Back Button:36 */
.MES36 {
background-color: $COL1;
&:hover { background-color: $COL1;}
color: $COL3;
padding: 5px;

 }
/* Contact Form:37 */
.me-block.me-Form.MES37,  body.MES37 {background-color:transparent;
 color: $COL19;
 
 input[type='submit']{border-style: solid;background-color: $COL19;
&:hover {background-color: $COL9}
border-width: 0;
color: $COL2;
padding: 10px;

}
@include placeholder($COL15);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 2px;
border-color: $COL15;
background-color: $COL1;
color: $COL15;
}
::-webkit-input-placeholder { color: $COL15; opacity: 0.5;}
:-moz-placeholder {color: $COL15; opacity: 0.5;} 
::-moz-placeholder {color: $COL15; opacity: 0.5;}
:-ms-input-placeholder {color: $COL15; opacity: 0.5;}

  }
/* Contact Form Button:38 */
.MES38 {
background-color: $COL19;
&:hover { background-color: $COL19;}
color: $COL2;
&:hover { color: $COL2;}
 }
/* Prod overall panel:39 */
.MES39 {
background-color: $COL2;
color: $COL3;
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
 }
.MES39 {
background-color: $COL2;
color: $COL3;
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
h1.MEC39, h2.MEC39, h3.MEC39, h4.MEC39, h5.MEC39, h6.MEC39 { color: $COL3;
 }
h1.MEC39 { font-size: 24.7px; }
h2.MEC39 { font-size: 20.9px; }
h3.MEC39 { font-size: 20.9px; }
h4.MEC39 { font-size: 17.1px; }
h5.MEC39 { font-size: 16.15px; }
h6.MEC39 { font-size: 15.2px; }
 }
a.MEC39 { color: $COL3;
&:hover { color: $COL3; }
 }
cite.MEC39{
color: $COL3;
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
}
/* :40 */
.me-block.me-ShareButton .MES40{
figure.MEC40{ a { font-size: 16px;

 }
 }

 }
/* Home Slider:41 */
.MES41 {
& .slider-arrow {background-color: $COL8;
color: $COL20;
font-size: 0px;
@media #{$medium-up} {
font-size: 30px;
};
@media #{$large-up} {
font-size: 40px;
};
}& .slider-arrow:hover{background-color: $COL16;
color: $COL2;
} }
/* rgb(255, 255, 255);:42 */
.MES42 {
 }
/* Sell car Form:24 */
.me-block.me-Form.MES24,  body.MES24 {background-color:transparent;
 color: $COL3;
 
 input[type='submit']{border-style: solid;background-color: $COL9;
&:hover {background-color: $COL9}
border-color: $COL15;
&:hover {border-color: $COL15}
border-width: 2px;
color: $COL11;
padding: 10px;

border-radius: 10px;
}
@include placeholder($COL3);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 2px;
border-radius: 10px 10px 10px 10px;
border-color: $COL15;
background-color: $COL17;
color: $COL3;
}
::-webkit-input-placeholder { color: $COL3; opacity: 0.5;}
:-moz-placeholder {color: $COL3; opacity: 0.5;} 
::-moz-placeholder {color: $COL3; opacity: 0.5;}
:-ms-input-placeholder {color: $COL3; opacity: 0.5;}

  }
/* Spacer top:22 */
.MES22 {
padding: 80px 0 0 0;

@media #{$medium-up} {
padding: 100px 0 0 0;

}
@media #{$large-up} {
padding: 120px 0 0 0;

}
 }
/* Dark:2 */
.MES2 {
background-color: $COL3;
color: $COL4;
 }
/* White Block:10 */
.MES10 {
background-color: $COL2;
padding: 8px;

 }
.MES10 {
background-color: $COL2;
padding: 8px;

 }
/* Dark:3 */
.MES3 {
background-color: $COL3;
color: $COL4;
 }
.MES3 {
background-color: $COL3;
color: $COL4;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL4;
 }
 }
a.MEC3 { color: $COL2;
&:hover { color: $COL2; }
 }
cite.MEC3{
color: $COL4;
}
/* Black Transparent:4 */
.MES4 {
background-color: $COL8;
&:hover, &.hover { background-color: $COL8;}
 }
.MES4 {
background-color: $COL8;
&:hover, &.hover { background-color: $COL8;}
 }
/* Left Menu:5 */
nav.me-Menu.MES5 {
& .menu-item.MEC5, & .menu-item.MEC5 > div.MEC5{ & > a.MEC5{color: $COL2;
font-size: 20px;
@media #{$large-up} {
font-size: 21px;
}text-transform: uppercase;
}
 &:hover > a.MEC5{color: $COL18;
}
 }
&.horizontal > .menu-item.MEC5 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC5 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC5 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC5 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 36px 5px 36px 20px;}

& .sub-menu .menu-item a{padding: 5px;}


& > .menu-item.MEC5.active { & > a{ color: $COL9;}
 }
&.horizontal .menu-item.MEC5:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid $COL8;}}
&.vertical .menu-item.MEC5:hover {& > .pointer-wrap > .pointer{border-right: 10px solid $COL8;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC5{background-color: $COL8;
 &:hover {background-color: $COL8}
}
& .menu-item.MEC5, & .menu-item.MEC5 > div.MEC5{ & > a.MEC5{color: $COL2;
font-size: 20px;
@media #{$large-up} {
font-size: 19px;
}text-transform: uppercase;
}
 &:hover > a.MEC5{color: $COL9;
}
 }

}
}
 }
/* Right Menu:6 */
nav.me-Menu.MES6 {
& .menu-item.MEC6, & .menu-item.MEC6 > div.MEC6{ & > a.MEC6{color: $COL2;
font-size: 20px;
@media #{$large-up} {
font-size: 21px;
}text-transform: uppercase;
}
 &:hover > a.MEC6{color: $COL9;
}
 }
&.horizontal > .menu-item.MEC6 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC6 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC6 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC6 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 36px 20px 36px 5px;}

& .sub-menu .menu-item a{padding: 10px;}


& > .menu-item.MEC6.active { & > a{ color: $COL9;}
 }
&.horizontal .menu-item.MEC6:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid $COL8;}}
&.vertical .menu-item.MEC6:hover {& > .pointer-wrap > .pointer{border-right: 10px solid $COL8;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC6{background-color: $COL8;
 &:hover {background-color: $COL8}
}
& .menu-item.MEC6, & .menu-item.MEC6 > div.MEC6{ & > a.MEC6{color: $COL2;
font-size: 20px;
@media #{$large-up} {
font-size: 19px;
}text-transform: uppercase;
}
 &:hover > a.MEC6{color: $COL9;
}
 }

}
}
 }
/* Light:11 */
.MES11 {
background-color: $COL11;
color: $COL12;
 }
/* Image Fix Panel:7 */
.MES7 {
background-color: $COL3;
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://duttongarage.com/img/72593/49007');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL2;
 }
.MES7 {
background-color: $COL3;
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://duttongarage.com/img/72593/49007');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL2;
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: $COL2;
 }
 }
cite.MEC7{
color: $COL2;
}
/* Join Form:8 */
.me-block.me-Form.MES8,  body.MES8 {background-color:transparent;
 color: $COL2;
 
 input[type='submit']{border-style: solid;background-color: $COL9;
&:hover {background-color: $COL9}
border-color: $COL15;
&:hover {border-color: $COL15}
border-width: 2px;
color: $COL11;
padding: 10px;

border-radius: 10px;
}
@include placeholder($COL2);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 2px;
border-color: $COL15;
background-color: $COL1;
color: $COL2;
}
::-webkit-input-placeholder { color: $COL2; opacity: 0.5;}
:-moz-placeholder {color: $COL2; opacity: 0.5;} 
::-moz-placeholder {color: $COL2; opacity: 0.5;}
:-ms-input-placeholder {color: $COL2; opacity: 0.5;}

  }
/* Footer Panel:9 */
.MES9 {
background-color: $COL3;
color: $COL4;
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
 }
.MES9 {
background-color: $COL3;
color: $COL4;
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: $COL4;
 }
 }
a.MEC9 { color: $COL2;
&:hover { color: $COL2; }
 }
cite.MEC9{
color: $COL4;
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
}
/* Light:12 */
.MES12 {
background-color: $COL11;
color: $COL12;
 }
.MES12 {
background-color: $COL11;
color: $COL12;
h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: $COL12;
 }
 }
cite.MEC12{
color: $COL12;
}
/* Image Fix Panel white Top:21 */
.MES21 {
background-color: $COL2;
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://duttongarage.com/img/72593/49007');
background-position: bottom center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}padding: 15px;

@media #{$medium-up} {
padding: 200px 20px;

}
@media #{$large-up} {
padding: 250px 50px 200px 50px;

}
 }
.MES21 {
background-color: $COL2;
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://duttongarage.com/img/72593/49007');
background-position: bottom center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}padding: 15px;

@media #{$medium-up} {
padding: 200px 20px;

}
@media #{$large-up} {
padding: 250px 50px 200px 50px;

}
 }
/* Alternate:13 */
.MES13 {
background-color: $COL13;
color: $COL14;
 }
/* Alternate:14 */
.MES14 {
background-color: $COL13;
color: $COL14;
 }
.MES14 {
background-color: $COL13;
color: $COL14;
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: $COL14;
 }
 }
cite.MEC14{
color: $COL14;
}
/* Image Bottom Panel:15 */
.MES15 {
background-color: $COL13;
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://duttongarage.com/img/72592/49008');
background-position: bottom center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;} }
.MES15 {
background-color: $COL13;
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://duttongarage.com/img/72592/49008');
background-position: bottom center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;} }
/* Banner Spacer:16 */
.MES16 {
padding: 15px;

@media #{$large-up} {
padding: 105px 0;

}
 }
/* Product Carousel:17 */
.MES17 {
& .slick-slider{padding: 0;}

& .slick-dots {text-align: center;
 button {margin: 0 5px;
;border-color: $COL3;
border-radius: 50%;
border-style:solid;
border-width:1px;
@media #{$medium-up} {
border-width: 1px;
};
@media #{$large-up} {
border-width: 1px;};
&:hover{border-color: $COL16;
;}
background-color: $COL3;
background-clip: padding-box;
&:hover {background-color: $COL16;}
width:10px;
height:10px;
@media #{$medium-up} {
width:10px;
height:10px;
};
@media #{$large-up} {
width:10px;
height:10px;
};
&:hover{background-color: $COL16;
;}}}
& .slick-dots .slick-active {
  button{
border-color: $COL16;
background-color: $COL16;
  }
}
& .slick-dots{bottom:-43px;
@media #{$medium-up} {
bottom: -27px;};
@media #{$large-up} {
bottom: -90px;};
} }
/* Small Padding:18 */
.MES18 {
padding: 0 5px;

 }
.MES18 {
padding: 0 5px;

 }
/* Bold Line:19 */
.MES19 {
& > hr {border-width: 2px 0 0 0;}
& > hr {border-color: $COL3 transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Image panel page:20 */
.MES20 {
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
 }
.MES20 {
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
 }
cite.MEC20{
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
}
/* credit panel:43 */
.MES43 {
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
 }
.MES43 {
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
 }
cite.MEC43{
font-size: 14px;
@media #{$large-up} {
font-size: 16.8px;
};
}
