@font-face {
  font-family: Novecentowide;
  src: url(/s~dutton18/2018/Novecentowide-Light-webfont.woff);
  font-weight: 300;
}

.scroll-down {
  display: none;
  @media #{$large-up} {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    z-index: 9;
    figure {
      background-color: rgba(0, 0, 0, 0.4);
      max-width: 80px;
      flex: 1;
      border: 1px solid #999;
      border-bottom: 0;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 0;
      a {color: #999;}
    }
  }
}
