$COL1: rgba(0,0,0,0); //
$COLFLAT1: #808080; //
$COL2: #ffffff; //
$COLFLAT2: #ffffff; //
$COL3: #1b1b1b; //
$COLFLAT3: #1b1b1b; //
$COL4: #ffffff; //
$COLFLAT4: #ffffff; //
$COL5: #1b1b1b; //
$COLFLAT5: #1b1b1b; //
$COL6: #1b1b1b; //
$COLFLAT6: #1b1b1b; //
$COL7: #b91f29; //
$COLFLAT7: #b91f29; //
$COL8: rgba(0,0,0,0.6); //Black 60%
$COLFLAT8: #333333; //Black 60%
$COL9: #cd372f; //Red
$COLFLAT9: #cd372f; //Red
$COL10: #ecbd63; //Gold
$COLFLAT10: #ecbd63; //Gold
$COL11: #ffffff; //
$COLFLAT11: #ffffff; //
$COL12: #1b1b1b; //
$COLFLAT12: #1b1b1b; //
$COL13: #efebe8; //
$COLFLAT13: #efebe8; //
$COL14: #1b1b1b; //
$COLFLAT14: #1b1b1b; //
$COL15: #848382; //grey
$COLFLAT15: #848382; //grey
$COL16: rgba(0,0,0,0.302); //black 30%
$COLFLAT16: #595959; //black 30%
$COL17: rgba(255,255,255,0.522); //White
$COLFLAT17: #c2c2c2; //White
$COL18: #b91f29; //
$COLFLAT18: #b91f29; //
$COL19: #605e5f; //Grey
$COLFLAT19: #605e5f; //Grey
$COL20: #e8e8e8; //Light Grey
$COLFLAT20: #e8e8e8; //Light Grey
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
