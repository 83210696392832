hr {
  margin: 0;
}

.me-block.me-Anchor {
  a {
    outline: none !important;
    &:focus {
      outline: none !important;
    }
    &:visited {
      outline: none !important;
    }
    &:hover {
      outline: none !important;
    }
  }
}

.slick-slide > div {
 width: 100%;
  display: flex;
}

.header {
  nav.mainmenu {
    .sub-menu {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      overflow: hidden;
      min-width: 225px !important;
    }
  }
  @media screen and (max-width: 1023px) and (min-width: 641px) {
    .me-Image {
      img {
        max-width: 165px !important;
      }
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1500px){
    .row {
      & > .column {
        padding: 0 !important;
      }
      justify-content: space-around !important;
      align-items: center !important;
      nav.mainmenu {
        justify-content: space-around;
        .sub-menu {
          left: 0 !important;
          transform: translateX(-10%) !important;
          min-width: 180px!important;
        }
       .menu-item > a {
          font-size: 1.5vw !important;
        }
      }
      .me-Image {
        img {
          max-width: 12vw !important;
        }
      }
    }
  }
}

.home-slider {
  .slider-arrow {
    display: none !important;
    @media #{$large-up} {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      border: 1px solid #999;
      //justify-content: center;
      //align-items: center;
      display: block !important;
      padding: 10px !important;
      font-size: 40px !important;
      position: relative;
      &.slider-arrow-left i{
        position: absolute;
        top: 5px;
        left: 18px;
      }
      &.slider-arrow-right i{
        position: absolute;
        top: 5px;
        right: 18px;
      }
    }
  }
  .item.column {
    @media #{$large-up} {
      height: 100vh;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100vw !important;
      .me-block.me-Image {
        display: none;
      }
    }
  }
}

nav.mainmenu {
  .sub-menu {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
    min-width: 225px !important;
  }
}

.prod-carousel {
  @media #{$small-only} {
    .slick-dots{display: none !important;}
  }
}

.download-button {
  position: absolute;
  top: 0;
  bottom: 0;
  .me-Button {display: none;}
  &:hover {
    .me-Button {display: block;}
  }
  @media #{$small-only} {
    display: none;
  }
}

.open-hour .column {
  & > .me-PanelCol {
    @media #{$small-up} {
      border-bottom: 1px dashed;
    }
    @media #{$large-up} {
      border-right: 1px dashed;
      border-bottom: 0 !important;
    }
  }
  &:last-child {
    & > .me-PanelCol {
      border: 0;
    }
  }
}

.prod-price-currency {
  display: flex;
  align-items: center;
  span {
    font-size: 16px;
    margin-left: 5px;
  }
}